<template>
  <div>
    <section class="lightColor review_date reference">
      <div class="review" v-if="company">
        <v-row class="mr-5 pr-5">
          <v-col sm="2" class="text-right">
            <b-avatar
              size="120"
              variant="info"
              :src="company['company_logo']"
            ></b-avatar>
          </v-col>
          <v-col sm="10">
            <b>
              <p class="font-size-20">{{ company["company_name"] }}</p>
              </b>
            <p class="font-size-14 text-muted">
              {{ company["tag_line"] }}
            </p>
            <p class="font-size-12">
              {{ company["rating"] }}
              <b-form-rating
                v-model="company['rating']"
                class="myrating pl-0"
                inline
                readonly
              ></b-form-rating>
              <span class="txt-brown cs-span"
                >{{ company["review"] }} Reviews</span
              >
            </p>
          </v-col>
        </v-row>
      </div>
    </section>
    <section class="mr-15 ml-15 p-5">
          <v-form ref="form" v-model="valid" lazy-validation>
      <v-row class="mr-5 pl-1">
        <h4>Write a Question</h4>
      
        <v-col sm="8">
        
          <label for="question" class="font-size-14">Question</label>

          <v-textarea
            outlined
            dense
            rows="1"
            cols="70" 
            id="question"
            class="mt-2 font-size-12 bg-transparent"
            placeholder="Enter Your Question"
            v-model="question"
            v-on:keydown="msg=false"
            :rules="QuestionRules"
          ></v-textarea>
      
        </v-col>
      </v-row>
       
      <v-row class="pr-5">
        <v-col>
          <b-button
            variant="outline-dark"
            class="button-custom-blck-company button-height-width font-size-14" @click="send()">
            Submit </b-button
          >

        </v-col>
      </v-row>
       <!-- <v-row class="pr-5">
        <v-col>
          <span v-if="msg" style="color:green">Question Submit Successfully </span>
        </v-col>
      </v-row> -->
         <div class="mt-3 alert alert-success alert-dismissible fade show" v-if="msg">
        <strong>Success!</strong> Your Question has been sent successfully.
    </div>
          </v-form>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  
  data: function () {
    return {
      question: null,
      company: null,
      userid:null,
      msg:false,
      QuestionRules: [(v) => !!v || "Question is required"],
    };
  },

  created() {
    this.getdata();
  },

  methods: {
    ...mapActions(["getCompanyProfile","sendQuestion"]),
    getdata() {
      console.log(this.$route.params.id)
      this.getCompanyProfile(this.$route.params.id).then((response) => {
        let rating = 0;
        let review = "";
       this.userid = response.data.data.user_id;
        if (response.data.data.company_review.length) {
          response.data.data.company_review.forEach((res, i) => {
            review = parseInt(i) + 1;
            rating = parseInt(rating) + parseInt(res.rating);
          });
        }
        response.data.data.rating = rating
          ? rating / parseInt(response.data.data.company_review.length)
          : 0;
        response.data.data.review = review;

        this.company = response.data.data;
         console.log( this.userid );
      });
     
    },
   
    send(){
       console.log( this.userid );
       if (this.$refs.form.validate()) {
  
this.sendQuestion({question:this.question,user_id:this.userid}).then((response) =>{
        console.log(response);
          this.msg=true;
        this.question= null;
         this.$refs.form.resetValidation();
      })
       
       } else {
        this.$refs.form.validate();
      }
      
    }
   
  },
};
</script>